import React, { useState, useEffect, useRef } from 'react';
import './gear.css';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./assets/GearImages', false, /\.(png|jpe?g|svg)$/)
);

function Gear() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isGearSticky, setIsGearSticky] = useState(false);
  const [scale, setScale] = useState(0.5); // Initial scale for gear-power
  const [moveUpBy, setMoveUpBy] = useState(0); // State to track upward movement
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Check if screen width is up to 768px
  const gearContainerRef = useRef(null);

  useEffect(() => {
    // Update isMobileView on window resize
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // For larger screens (more than 768px), handle scroll behavior
    if (!isMobileView) {
      const handleScroll = () => {
        const gearContainer = gearContainerRef.current;
        const gearContainerTop = gearContainer.offsetTop;
        const gearContainerHeight = gearContainer.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;
        const totalImages = images.length;

        // Calculate the scrollable height for the gear images to change
        const scrollableHeight = gearContainerHeight - windowHeight;
        const startScroll = gearContainerTop;
        const endScroll = gearContainerTop + scrollableHeight;

        if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
          // Calculate the current image index based on the scroll position
          const progress = (scrollPosition - startScroll) / scrollableHeight;
          const imageIndex = Math.floor(progress * totalImages);
          setCurrentImageIndex(Math.min(Math.max(imageIndex, 0), totalImages - 1));

          // Scale the gear-power background image based on scroll progress
          const newScale = 0.5 + progress * 0.5;
          setScale(Math.min(Math.max(newScale, 0.5), 1)); // Scale between 0.5 and 1

          // Check if we've reached the last image
          if (imageIndex >= totalImages - 1) {
            setIsGearSticky(true); // Make gear sticky at the last image
          } else {
            setIsGearSticky(false); // Reset sticky state
          }
        }

        // Start moving gear-watt and gear-torque up as they start moving into final position
        if (currentImageIndex >= 30) {
          const progress = Math.min((scrollPosition - startScroll) / scrollableHeight, 3);
          const moveUpValue = Math.min(progress * 200, 500); 
          setMoveUpBy(moveUpValue);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [currentImageIndex, isMobileView]);

  // Use the last image for mobile view (screen width up to 768px)
  const mobileImageIndex = images.length - 1;
  const currentImage = isMobileView
    ? images[mobileImageIndex]?.default || images[mobileImageIndex]
    : images[currentImageIndex]?.default || images[currentImageIndex];

  return (
    <>
      <div className="gear-container" ref={gearContainerRef}>
        <div className="content">
          <h2 className="content__title" data-splitting data-effect10 style={{ color: 'white' }}>
            <span className="font-medium font-1">THE</span>
            <span className="font-7">ULTIMATE RIDING</span>
            <span className="font-4">EXPERIENCE</span>
          </h2>
        </div>
        <div className="gear-heading">
          <div
            className="gear-power"
            style={{ transform: `scale(${isMobileView ? 1 : scale})` }} // Keep scale fixed on mobile
          ></div>
          <div className="gear2">
            {currentImage ? (
              <img src={currentImage} className="gear-pic" alt={`gear ${currentImageIndex}`} />
            ) : (
              <p>Loading Image...</p>
            )}
          </div>
        </div>
        <div
          className="gear-setting"
          style={{
            transform: isMobileView ? 'none' : `translateY(-${moveUpBy}px)`, // No movement for mobile view
          }}
        >
          <p className="gear-watt">
            POWER <br />
            <span className="font-watt">
              90 <span className="kilowatt-space">KW</span>
            </span>
          </p>
          <p className="gear-torque">
            TORQUE <br />
            <span className="font-watt">
              150 <span className="kilowatt-space">NM</span>
            </span>
          </p>
        </div>
        <div className="gear-text">
          <p className="gear-para">
            DISCOVER TRUE FREEDOM WITH MERCELLEINE <br /> SUPERBIKES OFFERING UNPARALLELED RANGE.
          </p>
        </div>
      </div>
    </>
  );
}

export default Gear;
