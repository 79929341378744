// import React, { Suspense, useRef, useEffect, useMemo, useState } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { OrbitControls, useGLTF, PerspectiveCamera, Environment, Stats, CameraShake } from '@react-three/drei';
// import * as THREE from 'three';

// // Bike Model Component
// function BikeModel({ mouseX }) {
//     const { scene } = useGLTF('/BikeModal.glb');
//     const bikeRef = useRef();
//     const pivotOffset = 0.5;

//     // Enable shadow casting for the bike model
//     useEffect(() => {
//         scene.traverse((child) => {
//             if (child.isMesh) {
//                 child.castShadow = true;
//             }
//         });
//     }, [scene]);

//     const bike = useMemo(() => <primitive object={scene} />, [scene]);

//     useFrame(() => {
//         if (bikeRef.current) {
//             const clampedRotationY = THREE.MathUtils.clamp(mouseX * 0.2, -0.2, 0.2);
//             bikeRef.current.rotation.y = THREE.MathUtils.lerp(bikeRef.current.rotation.y, clampedRotationY, 0.2);
//         }
//     });

//     useEffect(() => {
//         return () => {
//             if (bikeRef.current) {
//                 bikeRef.current.traverse((object) => {
//                     if (object.geometry) object.geometry.dispose();
//                     if (object.material) {
//                         if (object.material.map) object.material.map.dispose();
//                         object.material.dispose();
//                     }
//                 });
//             }
//         };
//     }, []);

//     return (
//         <group ref={bikeRef} position={[0, pivotOffset, 0]}>
//             <primitive object={scene} position={[-1, -pivotOffset, 0]} />
//         </group>
//     );
// }

// useGLTF.preload('/BikeModal.glb');

// // Background Model Component
// function Background() {
//     const { scene } = useGLTF('/bg1.glb');
//     const background = useMemo(() => <primitive object={scene} position={[0, -12, 0]} scale={[6, 5, -9]} />, [scene]);

//     return background;
// }

// // Floor Model Component
// function Floor() {
//     const { scene: floorScene } = useGLTF('/FloorCircle.glb');

//     useEffect(() => {
//         floorScene.traverse((child) => {
//             if (child.isMesh) {
//                 child.receiveShadow = true;
//                 child.material.metalness = 3;
//                 child.material.roughness = 0;
//             }
//         });
//     }, [floorScene]);

//     return <primitive object={floorScene} position={[0, -1, 0]} scale={[1, 0.1, 1]} rotation={[0, 0, 0]} />;
// }

// // Flooring Model Component
// function Flooring() {
//     const { scene: flooringScene } = useGLTF('/FloorMat.glb');
//     const flooring = useMemo(() => (
//         <primitive object={flooringScene} position={[0, -1, 0]} scale={[1, 0.1, 1]} />
//     ), [flooringScene]);

//     return flooring;
// }

// // Frame Limiter Component
// function FrameLimiter({ frameLimit = 60 }) {
//     let lastFrameTime = 0;

//     useFrame(({ clock }) => {
//         const delta = clock.getElapsedTime() - lastFrameTime;
//         const interval = 1 / frameLimit;

//         if (delta > interval) {
//             lastFrameTime = clock.getElapsedTime();
//         }
//     });

//     return null;
// }
// // Camera Control Component
// function CameraControl() {
//     const scrollRef = useRef(0);
//     const targetScroll = useRef(0);
//     const lockTransition = useRef(false);  // Fixed: Use useRef correctly

//     useEffect(() => {
//         const handleWheel = (event) => {
//             if (!lockTransition.current) {  // Access lockTransition.current
//                 targetScroll.current = Math.max(0, Math.min(2, targetScroll.current + event.deltaY * 0.004));
//             }
//         };

//         const handleScroll = () => {
//             if (window.scrollY === 0) {
//                 lockTransition.current = false;  // Update lockTransition.current
//             } else if (targetScroll.current >= 1) {
//                 lockTransition.current = true;  // Update lockTransition.current
//             }
//         };

//         window.addEventListener('wheel', handleWheel, { passive: false });
//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('wheel', handleWheel);
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     useFrame(({ camera }) => {
//         const startPos = [0, 5, 0.3];
//         const endPos = [0, 0.3, 5];

//         scrollRef.current = THREE.MathUtils.lerp(scrollRef.current, targetScroll.current, 0.3);

//         const lerpPos = scrollRef.current < 1 || !lockTransition.current  // Access lockTransition.current
//             ? startPos.map((start, i) => THREE.MathUtils.lerp(start, endPos[i], Math.min(scrollRef.current, 1)))
//             : endPos;

//         camera.position.set(...lerpPos);
//         camera.lookAt(0, 0, 0);
//         camera.updateProjectionMatrix();

//     });

//     return null;
// }

// // Bike Component
// function Bike() {
//     const [mouseX, setMouseX] = useState(0);
//     const [isVisible, setIsVisible] = useState(true);
//     const ref = useRef();
//     const directionalLightRefs = useRef(Array(4).fill(null));

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 setIsVisible(entry.isIntersecting);
//             },
//             { threshold: 0.1 }
//         );

//         if (ref.current) {
//             observer.observe(ref.current);
//         }

//         return () => {
//             if (ref.current) {
//                 observer.unobserve(ref.current);
//             }
//         };
//     }, []);

//     const handleMouseMove = (event) => {
//         const normalizedX = (event.clientX / window.innerWidth) * 2 - 1;
//         setMouseX(normalizedX);
//     };

//     useEffect(() => {
//         window.addEventListener('mousemove', handleMouseMove);
//         return () => window.removeEventListener('mousemove', handleMouseMove);
//     }, []);

//     return (
//         <><Stats /><Suspense fallback={null}>
//             <PerspectiveCamera makeDefault near={1} far={200} />

//             <CameraControl />


//             <group rotation={[0, Math.PI / 2, 0]} position={[0, -20, 0]}>
//                 <Background />
//             </group>


//             <group rotation={[0, Math.PI / 2, 0]} position={[0, -0.73, 0]} scale={0.85}>
//                 <BikeModel mouseX={mouseX} />
//                 <Environment files="./SmallEmpty.hdr" background={false} intensity={0.5} />


//                 {directionalLightRefs.current.map((_, index) => (
//                     <directionalLight
//                         ref={(el) => (directionalLightRefs.current[index] = el)}
//                         key={index}
//                         color="white"
//                         position={[[19.3, 13.4, -0.9], [19.4, -4.0, -16.7], [17.6, -7.7, 18.3], [0, -2, -5]][index]}
//                         intensity={[0.6, 1.8, 0.8, 0.8][index]} />
//                 ))}
//             </group>



//             <group position={[-0.9, 0.1, -0.25]} scale={0.8}>
//                 <Floor />
//             </group>


//             <group position={[-0.9, 0.10, -0.2]} scale={0.8}>
//                 <Flooring />
//             </group>

//             <FrameLimiter />


//             <OrbitControls enablePan={true} enableZoom={false} enableRotate={false} />

//         </Suspense></>
//     )
// }

// export default Bike;

import React, { Suspense, useRef, useEffect, useMemo, useState } from 'react';
import { Canvas, useFrame, useThree  } from '@react-three/fiber';
import { OrbitControls, useGLTF, PerspectiveCamera, Environment, Stats, CameraShake, useTexture } from '@react-three/drei';
import * as THREE from 'three';

// Bike Model Component
function BikeModel({ mouseX }) {
  const { scene } = useGLTF('/BikeTest4.glb');
  const bikeRef = useRef();
  const pivotOffset = 0.5;

  // Load the textures for the disk and allow images
  const diskTexture = useTexture('/diskBack.png');
  const alloyTexture = useTexture('/alloy.png');
  const alloyTexture1 = useTexture('/alloy.png');
  const alloyTexture2 = useTexture('/backDisc.png');

  // Enable shadow casting for the bike model
  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    });
  }, [scene]);

  // Image plane for the disk image
  const diskImage = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ map: diskTexture, transparent: true });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0.17, 1.66);  // Adjust position for disk
    mesh.scale.set(2.3, 1.3, 1.5);     // Adjust scale
    mesh.rotation.y = -Math.PI / 2;
    return mesh;
  }, [diskTexture]);

  // Image plane for the allow image (new image)
  const alloyImage = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ map: alloyTexture, transparent: true });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0.1, 0.16, 1.64);  // Adjust position for allow image
    mesh.scale.set(2.1, 1.15, 1.2);   // Adjust scale
    mesh.rotation.y = -Math.PI / 2;
    return mesh;
  }, [alloyTexture]);

  const alloyImage1 = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ map: alloyTexture1, transparent: true });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0.1, 0.18, -1.1);  // Adjust position for allow image
    mesh.scale.set(2.3, 1.3, 1.2);   // Adjust scale
    mesh.rotation.y = -Math.PI / 2;
    return mesh;
  }, [alloyTexture1]);

  const alloyImage2 = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ map: alloyTexture2, transparent: true });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0.10, -1.35);  // Adjust position for allow image
    mesh.scale.set(0.35, 0.35, 0);   // Adjust scale
    mesh.rotation.y = -Math.PI / 2;
    return mesh;
  }, [alloyTexture2]);

  useFrame(() => {
    if (bikeRef.current) {
      const clampedRotationY = THREE.MathUtils.clamp(mouseX * 0.2, -0.2, 0.2);
      bikeRef.current.rotation.y = THREE.MathUtils.lerp(bikeRef.current.rotation.y, clampedRotationY, 0.2);
    }
  });

  useEffect(() => {
    return () => {
      if (scene) {
        scene.traverse((object) => {
          if (object.geometry) object.geometry.dispose();
          if (object.material) {
            if (object.material.map) object.material.map.dispose();
            object.material.dispose();
          }
        });
      }
      // Dispose textures and geometries from the diskImage and alloyImage planes
      diskTexture.dispose();
      alloyTexture.dispose();
      alloyTexture1.dispose();
      alloyTexture2.dispose();
      diskImage.geometry.dispose();
      diskImage.material.dispose();
      alloyImage.geometry.dispose();
      alloyImage.material.dispose();
      alloyImage1.geometry.dispose();
      alloyImage1.material.dispose();
      alloyImage2.geometry.dispose();
      alloyImage2.material.dispose();
    };
  }, [scene, diskTexture, alloyTexture, alloyTexture1,alloyTexture2 , diskImage, alloyImage, alloyImage1,alloyImage2]);

  

  return (
    <group ref={bikeRef} position={[0, pivotOffset, 0]}>
      <primitive object={scene} position={[-1, -pivotOffset, 0]} />
      <primitive object={diskImage} />
      <primitive object={alloyImage} />
      <primitive object={alloyImage1} />
      <primitive object={alloyImage2} />
    </group>
  );
}

useGLTF.preload('/BikeTest4.glb');


// Background Model Component
function Background() {
  const { scene } = useGLTF('/bg1.glb');
  useEffect(() => {
    return () => {
      scene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) {
          if (object.material.map) object.material.map.dispose();
          object.material.dispose();
        }
      });
    };
  }, [scene]);
  return <primitive object={scene} position={[0, -13, 0]} scale={[6, 6, -9]} />;
}

useGLTF.preload('/bg1.glb');

// Floor Model Component (GLB)
function Floor() {
  const { scene: floorScene } = useGLTF('/FloorCircle.glb');
  useEffect(() => {
    floorScene.traverse((child) => {
      if (child.isMesh) {
        child.receiveShadow = true;
        child.material.metalness = 3;
        child.material.roughness = 0;
      }
    });

    return () => {
      floorScene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) object.material.dispose();
      });
    };
  }, [floorScene]);
  return <primitive object={floorScene} position={[0, -1, 0]} scale={[1, 0.1, 1]} />;
}

useGLTF.preload('/FloorCircle.glb');

// Flooring Model Component (GLB)
function Flooring() {
  const { scene: flooringScene } = useGLTF('/FloorMat.glb');
  useEffect(() => {
    return () => {
      flooringScene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) object.material.dispose();
      });
    };
  }, [flooringScene]);
  return <primitive object={flooringScene} position={[0, -1, 0]} scale={[1, 0.1, 1]} />;
}

useGLTF.preload('/FloorMat.glb');

// Frame Limiter Component
function FrameLimiter({ frameLimit = 50 }) {
  let lastFrameTime = 0;
  useFrame(({ clock }) => {
    const delta = clock.getElapsedTime() - lastFrameTime;
    const interval = 1 / frameLimit;
    if (delta > interval) {
      lastFrameTime = clock.getElapsedTime();
    }
  });
  return null;
}
// Camera Control Component
function CameraControl() {
  const scrollRef = useRef(0);
  const targetScroll = useRef(0);
  const [lockTransition, setLockTransition] = useState(false);

  useEffect(() => {
    const handleWheel = (event) => {
      if (!lockTransition) {
        targetScroll.current = Math.max(0, Math.min(2, targetScroll.current + event.deltaY * 0.004));
      }
    };

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setLockTransition(false);
      } else if (targetScroll.current >= 1) {
        setLockTransition(true);
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lockTransition]);

  useFrame(({ camera }) => {
    const startPos = [0, 5, 0.3];
    const endPos = [0, 0.3, 5];

    scrollRef.current = THREE.MathUtils.lerp(scrollRef.current, targetScroll.current, 0.3);

    const lerpPos = scrollRef.current < 1 || !lockTransition
      ? startPos.map((start, i) => THREE.MathUtils.lerp(start, endPos[i], Math.min(scrollRef.current, 1)))
      : endPos;

    camera.position.set(...lerpPos);
    camera.lookAt(0, 0, 0);
    camera.updateProjectionMatrix();

  });

  return null;
}

// Bike Component
function Bike() {
  const [mouseX, setMouseX] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const ref = useRef();
  const { gl } = useThree();
  const directionalLightRefs = useRef(Array(4).fill(null));
  // useEffect(() => {
  //     const observer = new IntersectionObserver(
  //         ([entry]) => {
  //             setIsVisible(entry.isIntersecting);
  //         },
  //         { threshold: 0.1 }
  //     );
  //     if (ref.current) {
  //         observer.observe(ref.current);
  //     }
  //     return () => {
  //         if (ref.current) {
  //             observer.unobserve(ref.current);
  //         }
  //     };
  // }, []);

  // useEffect(() => {
  //   // Set renderer size
  //   gl.setSize(window.innerWidth/ 2 , window.innerHeight/ 2);
  // }, [gl]);

  useEffect(() => {
    return () => {
      directionalLightRefs.current.forEach(light => light && light.dispose());
    };
  }, []);

  const handleMouseMove = (event) => {
    const normalizedX = (event.clientX / window.innerWidth) * 2 - 1;
    setMouseX(normalizedX);
  };
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);
  return (
    <>
      <Stats />
      <Suspense fallback={null}>
        <PerspectiveCamera makeDefault fov={45} near={1} far={200} />
        <CameraControl />
        <group rotation={[0, Math.PI / 2, 0]} position={[0, -20, 0]}>
          <Background />
        </group>
        <group rotation={[0, Math.PI / 2, 0]} position={[0, -0.73, 0]} scale={0.90}>
          <BikeModel mouseX={mouseX} />
          <Environment files="./SmallEmpty.hdr" background={false} intensity={0.5} />
          {directionalLightRefs.current.map((_, index) => (
            <directionalLight
              ref={(el) => (directionalLightRefs.current[index] = el)}
              key={index}
              color="white"
              position={[[19.3, 13.4, -0.9], [19.4, -4.0, -16.7], [17.6, -7.7, 18.3], [-5, -2, -5]][index]}
              intensity={[0.6, 1.8, 0.8, 0.8][index]}
            />
          ))}
        </group>
        <group position={[-0.9, 0.1, -0.25]} scale={0.8}>
          <Floor />
        </group>
        <group position={[-0.9, 0.10, -0.2]} scale={0.8}>
          <Flooring />
        </group>
        <FrameLimiter frameLimit={50} />
        <OrbitControls enablePan={true} enableZoom={false} enableRotate={true} />
      </Suspense>
    </>
  );
}
export default Bike;
