import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Logo from "./assets/Logo.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFading, setIsFading] = useState(false); // State to control fade effect
  const headerRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Convert 200vh to pixels
      const fadeStart = window.innerHeight * 2; // 200vh in pixels

      if (scrollY >= fadeStart) {
        setIsFading(true); // Start fading out after 200vh
      } else {
        setIsFading(false); // Reset opacity to full before 200vh
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className="header"
      ref={headerRef}
      style={{
        margin: 0,
        position: "fixed", // Keep the position fixed
        top: "0px",
        opacity: isFading ? 0 : 1, // Control opacity based on scrolling
        width: "100%",
        zIndex: 1000,
        transition: "opacity 0.5s ease", // Smooth opacity transition
      }}
    >
      <Link to="/">
        <img className="layer-1" src={Logo} alt="Layer 1 Logo" />
      </Link>
      <nav className={`nav-menu ${menuOpen ? "open" : ""}`}>
        <div className="about-contact">
          <Link to="/about" className="link link--helike">
            <span>About</span>
          </Link>
          <span className="separator1"> - </span>
          <Link to="/contact" className="link link--helike">
            <span>Contact</span>
          </Link>
        </div>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
        <span className={`bar ${menuOpen ? "open" : ""}`}></span>
      </div>
    </header>
  );
};

export default Header;
